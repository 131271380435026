import React from 'react';
import SEO from '../components/seo';
import logoSEO from '../images/logoPNG.png';
import AuthorizeErrorText from '../components/authorize/authorize-error-text/authorizeErrorText';
import Layout from '../components/layout';

const LoggedOut = () => (
	<Layout>
		<SEO
			description="Wyborek | Głosuj szybko i łatwo!"
			image={logoSEO}
			title="Automatyczne wylogowanie"
		/>
		<AuthorizeErrorText
			infoText="Nastąpiło automatyczne wylogowanie"
		/>
	</Layout>
);
export default LoggedOut;
