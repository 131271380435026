import React from 'react';
import styled from 'styled-components';
import IntroSvg from '../../../images/intro/intro-drawing.svg';
import LogoSvg from '../../../images/logo.svg';

const StyledIntroWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	width: 100%;
`;
const StyledIntroSvg = styled.img`
	margin-bottom: 60px;
`;
const StyledLogo = styled.img``;
const StyledLoadingText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 1rem;
	font-weight: 600;
	margin: 15px 0 35px;
	text-align: center;
	text-transform: lowercase;
	a {
		color: ${({ theme }) => theme.colors.aqua};
		text-decoration: none;
	}
`;
interface ErrorTextProps {
	infoText: string
}

const AuthorizeErrorText = ({ infoText } : ErrorTextProps) => (
	<StyledIntroWrapper>
		<StyledIntroSvg src={IntroSvg} />
		<StyledLogo src={LogoSvg} />
		<StyledLoadingText>
			{ infoText }
		</StyledLoadingText>
		<StyledLoadingText>
			<a href="https://wyborek.pl">wyborek.pl</a>
		</StyledLoadingText>
	</StyledIntroWrapper>
);
export default AuthorizeErrorText;
